<template>
  <div>
    <!-- Form: PropertyFeatureGroup Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: name -->
          <validation-provider
            #default="validationContext"
            vid="name"
            name="name"
            rules="required"
          >
            <b-form-group
              label="إسم المجموعة"
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="name"
                v-model="propertyFeatureGroupData.name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: display_name -->
          <validation-provider
            #default="validationContext"
            vid="display_name"
            name="display_name"
            rules="required"
          >
            <b-form-group
              label="إسم العرض للمجموعة"
              label-for="display_name"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="display-name"
                v-model="propertyFeatureGroupData.display_name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- Field: property_feature_group_id -->
          <validation-provider
            #default="validationContext"
            vid="property_feature_group_id"
            name="property_feature_group_id"
            rules="required"
          >
            <b-form-group
              label="المجموعة الرئيسية"
              label-for="property_feature_group_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="propertyFeatureGroupData.property_feature_group_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="property_feature_groups"
                :reduce="val => val.value"
                :clearable="false"
                input-id="property_feature_group_id"
                @input="property_feature_groupChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updatePropertyFeatureGroup"
          >
            حفظ التغييرات
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            إعادة ضبط
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormInvalidFeedback, BFormTextarea, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import propertyFeatureGroupStore from '../propertyFeatureGroupStore';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    propertyFeatureGroupData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    updatePropertyFeatureGroup() {
      this.$store
        .dispatch('app-propertyFeatureGroup/updatePropertyFeatureGroup', { propertyFeatureGroupData: this.propertyFeatureGroupData, id: this.propertyFeatureGroupData.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/propertyFeatureGroup').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
    property_feature_groupChanged(property_feature_group) {
      this.propertyFeatureGroupData.property_feature_group_id = property_feature_group;
    },

  },

  setup(props) {
    const PROPERTYFEATUREGROUP_STORE_MODULE_NAME = 'propertyFeatureGroup';

    // Register propertyFeatureGroup
    if (!store.hasModule(PROPERTYFEATUREGROUP_STORE_MODULE_NAME)) store.registerModule(PROPERTYFEATUREGROUP_STORE_MODULE_NAME, propertyFeatureGroupStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROPERTYFEATUREGROUP_STORE_MODULE_NAME)) store.unregisterModule(PROPERTYFEATUREGROUP_STORE_MODULE_NAME);
    });

    const property_feature_groups = ref([]);
    const res = store.dispatch('propertyFeatureGroup/fetchAllPropertyFeatureGroups')
      .then((response) => {
        const { data } = response;
        property_feature_groups.value = data.filter((c) => c.property_feature_group_id == null && c.name != 'property_location').map((c) => ({ label: c.display_name, value: c.id, ...c }));
      });

    const oldPropertyFeatureGroupData = JSON.parse(JSON.stringify(props.propertyFeatureGroupData));
    const resetPropertyFeatureGroupData = () => {
      propertyFeatureGroupData.value = JSON.parse(JSON.stringify(oldPropertyFeatureGroupData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPropertyFeatureGroupData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      property_feature_groups,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
